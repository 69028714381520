@import url("vendor/normalize.css");
@import url("vendor/flexboxgrid.css");
@import url("https://rsms.me/inter/inter.css");
@import "_color.scss";

@supports (font-variation-settings: normal) {
  body {
    font-family: "Inter var", system-ui, sans-serif;
  }
  body.chrome-win {
    /* Chrome has some VF issues; worst are on Windows with incorrect kerning.
       We disable VF on Chrome on Windows for that reason.
    */
    font-family: Inter, system-ui, sans-serif;
  }
}

/* ROOT FONT STYLES */

body {
  background-color: $grey-100;
  color: $blue-700;
  font-family: "Inter", system-ui, sans-serif;
  letter-spacing: -0.004em;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;

  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  font-kerning: normal;
  -webkit-font-kerning: normal;
  -ms-font-kerning: normal;
  -moz-font-kerning: normal;
  -o-font-kerning: normal;

  font-variant-ligatures: contextual common-ligatures;
  -webkit-font-variant-ligatures: contextual common-ligatures;
  -ms-font-variant-ligatures: contextual common-ligatures;
  -moz-font-variant-ligatures: contextual common-ligatures;
  -o-font-variant-ligatures: contextual common-ligatures;

  /* required to work around letter-spacing bug in Chrome: */
  font-feature-settings: "kern" 1, "liga" 1, "calt" 1;
}

/* FONT STYLES */
$wgh-black: 900;
$wgh-bold: 700;

/* CONTENT */

.header {
  h1 {
    margin: 0;
    font-weight: $wgh-black;
    font-size: 48px;
  }
  small {
    font-weight: $wgh-bold;
    font-size: 26px;
    color: $grey-500;
  }
  &.home {
    margin: 80px 0 38px 0;
    padding: 0 24px;
  }
  &.detail {
    margin: 24px 0 75px 0;
    padding: 0 24px;
  }
}

.posts {
  a {
    color: $blue-700;
    text-decoration: none;
  }
  .card {
    padding: 28px 24px;
    margin-bottom: 24px;
    font-weight: $wgh-bold;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid $grey-200;
    box-sizing: border-box;
    .title {
      font-size: 24px;
      margin-bottom: 15px;
    }
    .reddit-user {
      color: $blue-400;
      font-size: 18px;
      span.score {
        color: $grey-500;
        font-weight: normal;
      }
    }
  }
}

@media only screen and (max-width: 47em) {
  .posts {
    .card {
      margin-bottom: 0;
      border-radius: 0;
      border: 0;
      border-top: 1px solid #e2e7eb;
    }
  }
}

.back-to-home {
  padding: 0 24px;
  font-weight: $wgh-bold;
  color: $blue-400;
  text-decoration: none;
  margin-bottom: 24px;
  &:before {
    background-color: $blue-400;
    -webkit-mask-image: url(../assets/back.svg);
    mask-image: url(../assets/back.svg);
    mask-repeat: no-repeat;
    mask-size: 8px 16px;
    display: inline-block;
    position: relative;
    width: 8px;
    height: 16px;
    top: 2px;
    margin-right: 5px;
    content: "";
  }
}

.detail-content {
  font-size: 20px;
  margin-bottom: 43px;
  padding: 0 24px;
  h5 {
    font-weight: $wgh-bold;
    font-size: 26px;
    margin: 0 0 15px 0;
  }
}

.text-helper {
  padding: 0 24px;
  &.error {
    color: red;
  }
}
