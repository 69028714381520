/* COLOR PALLETE */
$blue-700: #263d52;
$blue-600: #25496e;
$blue-500: #34689d;
$blue-400: #4583c2;
$blue-300: #70a2d3;
$blue-200: #b2d3f2;
$blue-100: #f0f8fe;
$grey-700: #222933;
$grey-600: #616b79;
$grey-500: #8a95a5;
$grey-400: #bac4cd;
$grey-300: #d0d6dd;
$grey-200: #e2e7eb;
$grey-100: #f8f9fa;
